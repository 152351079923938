import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

export default function ({
  className = "",
  children,
  animation = "zoom-out-up",
  delay = 250,
  duration = 500,
  once = true,
  easing = "ease-in-sine",
}) {
  useEffect(() => {
    AOS.init({ disable: () => window.innerWidth < 992 });
  }, []);

  return (
    <div
      className={className}
      data-aos={animation}
      data-aos-delay={delay}
      data-aos-duration={duration}
      data-aos-once={once}
      data-aos-easing={easing}
    >
      {children}
    </div>
  );
}
